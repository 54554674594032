import React from 'react'
import { Provider } from 'react-redux'
import { createStore as reduxCreateStore } from 'redux'
import rootReducer from '.'

const createStore = () => reduxCreateStore(rootReducer)

const MyComponent = ({ element }) => {
    console.log("--------------------------------------------")
    console.log(
        "%cMade by: Invisual Branding Solutions",
        'text-align: center; color: #006cff;font-size: 18px; font-family: "Roboto", sans-serif'
    )
    console.log("--------------------------------------------")


    return (
        <Provider store={createStore()}>{element}</Provider>
    )
}

export default MyComponent
